import "@/scss/styles.scss";
import { getGlobal } from "@/getters/getGlobal";
import { Suspense } from "react";
import { SiteFooter, SiteHeader, ReciteMeWidget } from "@/ui";
import SourceFlowHead from "@sourceflow-uk/sourceflow-head";
import metaObject from "../../.sourceflow/metadata.json";

export default function App({ Component, pageProps }) {
  const global = getGlobal();

  switch (pageProps.layout) {
    case "minimal":
      return (
        <Suspense>
          <main className="minimal flex-grow-1">
            <Component {...pageProps} />
          </main>
        </Suspense>
      );

    default:
    case "default":
      return (
        <Suspense>
          <SourceFlowHead metaObject={metaObject} addTracker={true} cookieExpiryTime={3000}>
            {pageProps.meta?.title && <title>{pageProps.meta.title}</title>}
            {pageProps.meta?.canonical && <link rel="canonical" href={pageProps.meta.canonical} />}
          </SourceFlowHead>
          <SiteHeader className={global["_theme.header.classes"]} />
          <main className="main flex-grow-1">
            <Component {...pageProps} />
          </main>
          <SiteFooter className={global["_theme.footer.classes"]} />
          <ReciteMeWidget />
        </Suspense>
      );
  }
}
